@font-face {
  font-family: Neris;
  src: url(../fonts/Neris-SemiBold.woff2) format("woff2"),url(../fonts/Neris-SemiBold.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: Neris;
  src: url(../fonts/Neris-Black.woff2) format("woff2"),url(../fonts/Neris-Black.woff) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: Neris;
  src: url(../fonts/Neris-Light.woff2) format("woff2"),url(../fonts/Neris-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap
}

@font-face {
  font-family: Neris-SemiBold;
  src: url(../fonts/Neris-SemiBold.woff2) format("woff2"),url(../fonts/Neris-SemiBold.woff) format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: Neris-Black;
  src: url(../fonts/Neris-Black.woff2) format("woff2"),url(../fonts/Neris-Black.woff) format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap
}
@font-face {
  font-family: Neris-Light;
  src: url(../fonts/Neris-Light.woff2) format("woff2"),url(../fonts/Neris-Light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap
}
