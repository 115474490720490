body #CybotCookiebotDialog {
    font-family: Neris-Light, sans-serif;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
}

body #CybotCookiebotDialog #CybotCookiebotDialogPoweredbyImage {
    opacity: 0;
    pointer-events: none;
    height: 0;
}

body #CybotCookiebotDialog #CybotCookiebotDialogPoweredbyCybot {
    display: none;
}

body #CybotCookiebotDialog #CybotCookiebotDialogBody {
    font-size: 16px;
    line-height: 24px;
}

body #CybotCookiebotDialog #CybotCookiebotDialogBody .CybotCookiebotDialogBodyBottomWrapper {
    display: none;
}

body #CybotCookiebotDialog #CybotCookiebotDialogBodyContentTitle {
    font-family: Neris-SemiBold, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    color: #363D40;
}

@media all and (min-width: 1024px) {
    body #CybotCookiebotDialog #CybotCookiebotDialogBodyContentTitle {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px;
    }
}

body #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText {
    font-size: 16px;
    line-height: 24px;
    color: #363D40;
}

@media all and (min-width: 1024px) {
    body #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText {
        font-size: 18px;
        letter-spacing: 0;
        line-height: 28px;
    }
}

body #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a {
    font-family: Neris-Light, sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
}

@media all and (min-width: 1024px) {
    body #CybotCookiebotDialog #CybotCookiebotDialogBodyContentText a {
        font-size: 18px;
        line-height: 28px;
    }
}

body #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: #E4EBED;
}

body #CybotCookiebotDialog form input[type=checkbox][disabled]:checked + .CybotCookiebotDialogBodyLevelButtonSlider {
    background-color: #A12971;
    opacity: 0.5;
}

body #CybotCookiebotDialog .CybotCookiebotDialogBodyLevelButtonSliderWrapper:hover .CybotCookiebotDialogBodyLevelButtonSlider {
    outline: 2px solid #A12971;
}

body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll,
body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection,
body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize,
body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonDecline {
    font-family: Neris-SemiBold, sans-serif;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    border-radius: 8px;
    line-height: 16px;
    font-size: 16px;
    height: 48px;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    -webkit-transition: border-color 0.3s, background-color 0.3s cubic-bezier(0.35, 0, 0, 1);
    transition: border-color 0.3s, background-color 0.3s cubic-bezier(0.35, 0, 0, 1);
    box-sizing: border-box;
    background-color: #A12971;
    color: #F2F6F7;
    border: none;
}

body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll:hover,
body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection:hover,
body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize:hover,
body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyButtonDecline:hover {
    color: #F2F6F7;
    background-color: #871459;
    opacity: 1;
}

body #CybotCookiebotDialogFooter #CybotCookiebotDialogBodyLevelButtonCustomize .CybotCookiebotDialogArrow {
    display: none;
}

body .cookies-overlay {
    top: 0;
    left: 0;
    opacity: 0.3;
    pointer-events: auto;
    width: 100%;
    z-index: 1000;
    bottom: 0;
    position: fixed;
    background: #202A2E;
    -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0, 1);
    transition: all 0.3s cubic-bezier(0.35, 0, 0, 1);
    visibility: visible;
}

body .cookies-overlay.hidden {
    display: none;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
}